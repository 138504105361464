/**
* Template Name: iPortfolio
* Template URL: https://bootstrapmade.com/iportfolio-bootstrap-portfolio-websites-template/
* Updated: Jun 12 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
  --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway",  sans-serif;
  --nav-font: "Poppins",  sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root { 
  --background-color: #f6f9fc; /* Background color for the entire website, including individual sections */
  --default-color: #00043c; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #1032cf; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #0d28a3; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --underline-color: #efde4d;
  --contrast-color: #ffffff; /* The contrast color is used for elements when the background color is one of the heading, accent, or default colors. Its purpose is to ensure proper contrast and readability when placed over these more dominant colors */
  --sub-title:#20275c;
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #acbed6;  /* The default color of the main navmenu links */
  --nav-hover-color: #ffffff; /* Applied to main navmenu links when they are hovered over or active */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #b8d8ff; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Template Custom Colors */
:root {
  --header-background-color: #040b14;
  --header-background-color: #00043c;
  --header-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
@font-face {
	font-family: 'sdb-regular';
	src: url('Sambhu-Regular.ttf');
    font-weight:400;
}

@font-face {
	font-family: 'UbuntuCondensed';
	src: url('UbuntuCondensed-Regular.ttf');
}
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
  font-family: 'sdb-regular';
  font-size:1.2rem!important;
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;
}

@media (min-width: 800px) {
  nav ol li a {
    margin-top: -9px;
    margin-bottom: -9px;
  }
}

@media (max-width: 800px) {
  nav ol li a {
    font-size: 1.0rem!important;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  nav ol li {
    display: block;
    width:100%;
  }
  .page-title .breadcrumbs ol li+li::before {
    content: ""!important;
    display: inline-block;
    padding: 10px 0px!important;
    color: none!important;
    }
  .page-title .breadcrumbs ol li+li {
      padding-left: 0px!important;
  }

}
.img-colab{
  max-width: 30px;
  margin-top: -5px !important;
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
  font-family: 'UbuntuCondensed';
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
  --background-color: var(--header-background-color);
  --default-color: var(--header-color);
  --heading-color: var(--header-color);
  color: var(--default-color);
  background-color: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0 15px;
  width: 300px;
  transition: all ease-in-out 0.3s;
  overflow-y: auto;
  z-index: 999999999999;
  border-right: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.header .profile-img img {
  margin: 15px auto;
  display: block;
  width: 120px;
  border: 8px solid color-mix(in srgb, var(--default-color), transparent 85%);
}

.header .logo {
  line-height: 1;
  margin-bottom: 15px;
}

.header .logo img {
  max-height: 32px;
  margin-right: 8px;
}

.header .logo h1 {
  font-size: 24px;
  margin: 0;
  font-weight: 100;
  color: var(--heading-color);
}

.header .social-links {
  margin: 0 0 20px 0;
}

.header .social-links a {
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: color-mix(in srgb, var(--default-color), transparent 90%);
  color: var(--default-color);
  margin: 0 2px;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  transition: 0.3s;
}

.header .social-links a:hover {
  color: var(--contrast-color);
  background: var(--accent-color);
}

@media (min-width: 1200px) {

  .header~main,
  .header~#footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  .header {
    left: -100%;
  }
}

.header.header-show {
  left: 0;
}

.header .header-toggle {
  color: var(--contrast-color);
  background-color: var(--accent-color);
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;
  transition: background-color 0.3s;
}

.header .header-toggle:hover {
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--accent-color) 90%, white 15%);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
.mobile-nav-toggle {
  display: none;
}

.navmenu {
  padding: 0;
  z-index: 9997;
}

.navmenu ul {
  list-style: none;
  /*padding: 0 0 20px 0;*/
  margin: 0;
}

.navmenu a,
.navmenu a:focus {
  color: var(--nav-color);
  padding: 5px 10px;
  font-family: var(--nav-font);
  font-family: 'UbuntuCondensed';
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;
  transition: 0.3s;
  width: 100%;
}

.navmenu a .navicon,
.navmenu a:focus .navicon {
  font-size: 20px;
  margin-right: 10px;
}

.navmenu a .toggle-dropdown,
.navmenu a:focus .toggle-dropdown {
  font-size: 12px;
  line-height: 0;
  margin-left: auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
  flex-shrink: 0;
  background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
}

.navmenu a .toggle-dropdown:hover,
.navmenu a:focus .toggle-dropdown:hover {
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.navmenu a:hover,
.navmenu .active,
.navmenu .active:focus {
  color: var(--nav-hover-color);
}

.navmenu a:hover .navicon,
.navmenu .active .navicon,
.navmenu .active:focus .navicon {
  color: var(--header-color);
}

.navmenu .active .toggle-dropdown,
.navmenu .active:focus .toggle-dropdown {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  transform: rotate(180deg);
}

.navmenu .dropdown {
  display: block;
}

.navmenu .dropdown a,
.navmenu .dropdown a:focus {
  color: --nav-dropdown-color;
}

.navmenu .dropdown a:hover,
.navmenu .dropdown .active,
.navmenu .dropdown .active:focus {
  color: var(--nav-dropdown-hover-color);
}

.navmenu .dropdown ul {
  position: static;
  display: none;
  z-index: 99;
  padding: 5px 10px;
  margin: 5px 10px;
  background-color: var(--nav-dropdown-background-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  box-shadow: none;
  transition: all 0.5s ease-in-out;
}

.navmenu .dropdown ul ul {
  background-color: rgba(33, 37, 41, 0.1);
}

.navmenu .dropdown>.dropdown-active {
  display: block;
  background-color: rgba(33, 37, 41, 0.03);
}

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.footer {
  --background-color: color-mix(in srgb, var(--default-color), transparent 97%);
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  padding: 40px 0;
  position: relative;
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .credits {
  margin-top: 4px;
  font-size: 13px;
  text-align: center;
}
.footer .big {
  margin-top: 4px;
  font-size: 18px;
  text-align: center;
}
/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.page-title {
  --background-color: color-mix(in srgb, var(--default-color), transparent 96%);
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 25px 0;
  position: relative;
}

.page-title h1 {
  font-size: 24px;
  font-weight: 700;
}

.page-title .breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.page-title .breadcrumbs ol li+li {
  padding-left: 10px;
}

.page-title .breadcrumbs ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  overflow: clip;
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.section-title {
  padding-bottom: 60px;
  position: relative;
}

.section-title h2 {
  font-size: 40px;
  font-weight: 200;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  letter-spacing: -2px;
}

.section-title h2:after {
  content: " ";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--accent-color);
  left: 0;
  bottom: 0;
}

.section-title p {
  margin-bottom: 0;
}

.text-gradient{
  background: -webkit-linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;  
}

.border-circle{
    border: 8px solid color-mix(in srgb, var(--default-color), transparent 85%);
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  --default-color: #ffffff;
  --background-color: #f6f9fc;
  --heading-color: #ffffff;
  width: 100%;
  min-height: 30vh;
  position: relative;
  padding: 80px 0;
  display: flex;
  align-items: self-end;
  justify-content: center;
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 70%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 3.0rem;
  font-weight: 100;
  letter-spacing: -3px;
}

.hero p {
  margin: 5px 0 0 0;
  font-size: 46px;
  font-weight:800;
}

.hero p span {
  letter-spacing: 0px;
}

.hero .social-links {
  margin-top: 25px;
}

.hero .social-links a {
  font-size: 24px;
  display: inline-block;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  line-height: 1;
  margin-right: 20px;
  transition: 0.3s;
}

.hero .social-links a:hover {
  color: var(--accent-color);
}

.round-pic{
    border-radius:50%;
    
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
  }

  .hero p {
    font-size: 20px;
  }
}
/*--------------------------------------------------------------
# Tech-icons Section
--------------------------------------------------------------*/
@media (max-width: 768px) {
    .tech_icons{display:none;}
}
.tooltip{font-size:19px;}
.tech_icons{
    transition: ease-out 0.5s;
    background: var(--default-color);
    background:#000000;
}
.tech_icons img{
   -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
   filter: grayscale(100%);
    max-width: 55px;
    max-height: 55px;
}
.tech_icons img:hover{
   -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
   filter: grayscale(0%);
}
@media (min-width: 1140px) {
.tech_icons .col-md-1{
        padding: 8px 20px;
        text-align:center;
        transition: ease-out 0.2s;
    }
.tech_icons .col-md-1:hover{
        padding: 4px 16px;
        text-align:center;
       transition: ease-out 0.2s;
    }
}
@media (max-width: 1140px) {
.tech_icons .col-md-1{
        padding: 8px 5px;
        text-align:center;
        transition: ease-out 0.2s;
    }
.tech_icons .col-md-1:hover{
        padding: 5px 2px;
        text-align:center;
       transition: ease-out 0.2s;
    }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .content h2 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0px;
  color: var(--heading-color);
  margin-bottom: 0px;
}
.about .content h2 span{ border-bottom:2px dotted #CCC;}
.about h1{
  font-size: 2.5rem;
  text-align: center;
  margin: 40px 0;
  font-family: 'sdb-regular';
  color: var(--default-color);
  letter-spacing: -1.5px;
}

.about .content h2::after {
  display:none;
}

.about .content strong{
  color: var(--heading-color);
}

.about .content ul {
  list-style: none;
  padding: 0;
  margin-left: 1rem;
}

.about .content ul li {
  display: flex;
  align-items: center;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: var(--accent-color);
  line-height: 0;
}

/*--------------------------------------------------------------
# Stats Section
--------------------------------------------------------------*/
.stats .stats-item {
  padding: 30px;
  width: 100%;
}

.stats .stats-item i {
  color: var(--accent-color);
  display: block;
  font-size: 44px;
  float: left;
  line-height: 0;
}

.stats .stats-item .purecounter {
  color: var(--heading-color);
  font-size: 48px;
  line-height: 40px;
  display: block;
  font-weight: 700;
  margin-left: 60px;
}

.stats .stats-item p {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 15px 0 0 0;
  margin: 0 0 0 60px;
  font-family: var(--heading-font);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Skills Section
--------------------------------------------------------------*/
.skills {
  --background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  color: var(--heading-color);
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: var(--heading-font);
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: color-mix(in srgb, var(--default-color), transparent 90%);
  height: 10px;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: var(--accent-color);
}

/*--------------------------------------------------------------
# Resume Section
--------------------------------------------------------------*/
.resume .resume-title {
  color: var(--heading-color);
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid var(--accent-color);
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: var(--background-color);
  border: 2px solid var(--accent-color);
}

/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/
.portfolio {
  --background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.portfolio .portfolio-filters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio .portfolio-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  margin: 0 10px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-filters li:hover,
.portfolio .portfolio-filters li.filter-active {
  color: var(--accent-color);
}

.portfolio .portfolio-filters li:first-child {
  margin-left: 0;
}

.portfolio .portfolio-filters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio .portfolio-filters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.portfolio .portfolio-content {
  position: relative;
  overflow: hidden;
}

.portfolio .portfolio-content img {
  transition: 0.3s;
}

.portfolio .portfolio-content .portfolio-info {
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: rgba(0, 0, 0, 0.6);
  padding: 15px;
}

.portfolio .portfolio-content .portfolio-info h4 {
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  background-color: var(--accent-color);
}

.portfolio .portfolio-content .portfolio-info p {
  position: absolute;
  bottom: 10px;
  text-align: center;
  display: inline-block;
  left: 0;
  right: 0;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
}

.portfolio .portfolio-content .portfolio-info .preview-link,
.portfolio .portfolio-content .portfolio-info .details-link {
  position: absolute;
  left: calc(50% - 40px);
  font-size: 26px;
  top: calc(50% - 14px);
  color: #fff;
  transition: 0.3s;
  line-height: 1.2;
}

.portfolio .portfolio-content .portfolio-info .preview-link:hover,
.portfolio .portfolio-content .portfolio-info .details-link:hover {
  color: var(--accent-color);
}

.portfolio .portfolio-content .portfolio-info .details-link {
  left: 50%;
  font-size: 34px;
  line-height: 0;
}

.portfolio .portfolio-content:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-content:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .service-item {
  position: relative;
}

.services .service-item .icon {
  background: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  transition: 0.5s;
  border: 1px solid var(--accent-color);
  margin-right: 20px;
}

.services .service-item .icon i {
  color: var(--contrast-color);
  font-size: 24px;
  line-height: 0;
}

.services .service-item:hover .icon {
  background: var(--contrast-color);
}

.services .service-item:hover .icon i {
  color: var(--accent-color);
}

.services .service-item .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .service-item .title a {
  color: var(--heading-color);
}

.services .service-item .title a:hover {
  color: var(--accent-color);
}

.services .service-item .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials {
  --background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  text-align: center;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, var(--header-background-color), transparent 40%);
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background-color: var(--accent-color);
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px color-mix(in srgb, var(--default-color), transparent 90%);
}

.testimonials .testimonial-item p::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid var(--contrast-color);
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.testimonials .swiper-wrapper {
  height: auto;
}

.testimonials .swiper-pagination {
  margin-top: 40px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--background-color);
  opacity: 1;
  border: 1px solid var(--accent-color);
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-wrap {
  box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
  padding: 30px;
}

@media (max-width: 575px) {
  .contact .info-wrap {
    padding: 20px;
  }
}

.contact .info-item {
  margin-bottom: 40px;
}

.contact .info-item i {
  font-size: 20px;
  color: var(--accent-color);
  background: color-mix(in srgb, var(--accent-color), transparent 92%);
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h3 {
  font-size: 20px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .info-item:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.contact .php-email-form {
  height: 100%;
  padding: 30px;
  box-shadow: 0px 0px 20px color-mix(in srgb, var(--default-color), transparent 90%);
}

@media (max-width: 575px) {
  .contact .php-email-form {
    padding: 20px;
  }
}

.error{color:#ff0000;font-size: 1.0rem;}

.contact .php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: var(--background-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--background-color);
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
  color: var(--contrast-color);
  background: var(--accent-color);
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 25%);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Portfolio Details Section
--------------------------------------------------------------*/
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px color-mix(in srgb, var(--default-color), transparent 90%);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid color-mix(in srgb, var(--default-color), transparent 85%);
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: block;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid color-mix(in srgb, var(--default-color), transparent 70%);
  margin: 20px 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.service-details .services-list a.active {
  color: var(--heading-color);
  font-weight: 700;
  border-color: var(--accent-color);
}

.service-details .services-list a:hover {
  border-color: var(--accent-color);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 26px;
  font-weight: 700;
}

.service-details h4 {
  font-size: 20px;
  font-weight: 700;
}

.service-details p {
  font-size: 15px;
}

.service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}


/*
New Preloader
*/

#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: var(--background-color);
  transition: all 0.6s ease-out;
}
 
#preloader div.lds-ripple {
   content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(60% - 0px);
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
 /**/

/*--------------------------------------------------------------
# Preloader animation on mobile devices
--------------------------------------------------------------*/

@media screen and (max-width: 1199px) {
  #preloader div.lds-ripple{
    left: calc(50% - 30px)!important;
  }
}
.lds-ripple {
  /* change color here */
  color: #1c4c5b
}
.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  z-index: 9999999;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}
/*New preloader ends*/